import { Link, NavLink } from "@liberetech/design-system";
import ActionSheet from "@liberetech/design-system/ActionSheet";
import Button from "@liberetech/design-system/Button";
import classNames from "classnames";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Control, useController } from "react-hook-form";

import {
  useFeatureIsOn,
  useFeaturesReady,
} from "components/Context/ExperimentContext";
import { useSearchBar } from "components/Context/PageContext";
import { useSession } from "components/Context/SessionContext";
import Skeleton from "components/Skeleton/Skeleton";
import {
  fromApiDateToDate,
  fromDate,
  fromDateToApiDate,
} from "lib/formatter/date";
import { Trans, useLanguage, useTranslation } from "lib/i18n/client";
import {
  Promotion as PromotionType,
  promotionForBrand,
} from "lib/promotion/promotion";
import useForm from "lib/useForm";
import { Brand, Locale } from "types";

import styles from "./LayoutSearchBar.module.css";
import LayoutSearchBarDatePicker from "./LayoutSearchBarDatePicker";

const MOBILE_BREAKPOINT = 1024;

const LayoutSearchBar: React.FC<LayoutSearchBarProps> = ({
  className,
  isScrolled,
  options,
  optionsDefault,
  type,
  brand,
}) => {
  const searchBar = useSearchBar();
  const { t } = useTranslation("commonSearchBar");
  const lang = useLanguage();
  const { query } = useRouter();
  const [isMobile, setMobile] = useState<boolean>(true);
  const dateFormat = new Intl.DateTimeFormat(lang, {
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });
  const from = new Date(query.from as string);
  const to = new Date(query.to as string);

  const promotion = promotionForBrand(brand);

  useEffect(() => {
    const onScroll = () => {
      if (!isMobile) {
        searchBar.close();
      }
    };
    const onResize = () => {
      setMobile(window.innerWidth < MOBILE_BREAKPOINT);
    };
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isMobile]);

  useEffect(() => {
    setMobile(window.innerWidth < MOBILE_BREAKPOINT);
  }, []);

  return (
    <div
      className={classNames(styles.searchBar, className, {
        [styles.isScrolled]: isScrolled,
        [styles.isOpen]: searchBar.isOpen,
      })}
    >
      <Button
        onClick={() => searchBar.open()}
        className={styles.searchBarToggle}
        icon={
          <svg viewBox="0 0 32 32">
            <path d="m30 30-8.5-8.5" />
            <path d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z" />
          </svg>
        }
      >
        {!isNaN(from.getTime()) && !isNaN(to.getTime())
          ? `${dateFormat.format(from)} - ${dateFormat.format(to)}`
          : t("findAccomodation")}
      </Button>
      {isMobile ? (
        <ActionSheet
          isOpen={searchBar.isOpen}
          title={t("findAccomodation")}
          onRequestClose={() => {
            searchBar.close();
          }}
        >
          <Promotion brand={brand} promotion={promotion} />
          <SearchForm
            type={type}
            options={options || []}
            optionsDefault={optionsDefault}
            onSubmitSuccess={() => {
              searchBar.close();
            }}
            brand={brand}
            isMobile={isMobile}
          />
        </ActionSheet>
      ) : (
        <div className={styles.searchFormDesktop}>
          <SearchForm
            type={type}
            options={options || []}
            optionsDefault={optionsDefault}
            brand={brand}
            isMobile={isMobile}
          />
          <Promotion brand={brand} promotion={promotion} />
        </div>
      )}
    </div>
  );
};

const SearchForm: React.FC<{
  type: "asset" | "destination";
  options: Array<{ title: string; uri: string }>;
  optionsDefault?: string;
  onSubmitSuccess?: () => void;
  brand: Brand;
  isMobile: boolean;
}> = ({ type, options, optionsDefault, onSubmitSuccess, brand, isMobile }) => {
  const { push, query } = useRouter();
  const { t } = useTranslation("commonSearchBar");
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<SearchFormFields>({
    translationContext: "common",
    defaultValues: {
      from: query.from ? fromApiDateToDate(query.from as string) : undefined,
      to: query.to ? fromApiDateToDate(query.to as string) : undefined,
      voucher: query.voucher as string,
    },
  });

  useEffect(() => {
    if (optionsDefault) {
      setValue("assetUri", optionsDefault);
    }
  }, [optionsDefault]);

  return (
    <form
      className={styles.searchForm}
      noValidate
      onSubmit={handleSubmit(({ assetUri, from, to, voucher }) => {
        const queryParams = new URLSearchParams();
        const [, path, anchor] = assetUri.match(/(.*)(#.*)/) || ["", assetUri];
        queryParams.set("from", (from && fromDateToApiDate(from)) || "");
        queryParams.set("to", (to && fromDateToApiDate(to)) || "");
        queryParams.set("voucher", voucher || "");
        push(`${path}?${queryParams.toString()}${anchor || ""}`);
        onSubmitSuccess && onSubmitSuccess();
      })}
    >
      <SearchInputWrapper
        label={t(type)}
        icon={
          <svg viewBox="0 0 24 24">
            <path d="m20.25 5.4 3-.9v15l-7.5 3-7.5-3-7.5 3v-15l3-1.2m13.5-.436C17.25 9.02 12 14.25 12 14.25S6.75 9.02 6.75 5.864C6.75 2.627 9.463.75 12 .75s5.25 1.878 5.25 5.114ZM13.5 6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
          </svg>
        }
        error={
          errors.assetUri?.type === "required"
            ? t("common:validation.required")
            : undefined
        }
      >
        <select required {...register("assetUri", { required: true })}>
          {options.length !== 1 && (
            <option value="" hidden>
              {t(`${type}Placeholder`)}
            </option>
          )}
          {options.map((option, i) => (
            <option value={option.uri} key={i}>
              {option.title}
            </option>
          ))}
        </select>
      </SearchInputWrapper>
      <SearchInputWrapper
        label={t("dates")}
        icon={
          <svg viewBox="0 0 24 24">
            <path d="M23.25 8.25H.75m18-5.25h4.5v20.25H.75V3h4.5m3 0h7.5M5.25 15.75V12h13.5v3.75h-4.5m-9 0v3.75h9v-3.75m-9 0h9m0 0V12m-4.5.75v6.75M5.25.75h3v4.5h-3V.75Zm10.5 0h3v4.5h-3V.75Z" />
          </svg>
        }
        error={
          errors.from?.type === "required" || errors.to?.type === "required"
            ? t("common:validation.required")
            : undefined
        }
      >
        <SearchInputDatePicker
          control={control}
          numberOfMonths={isMobile ? 1 : 2}
        />
      </SearchInputWrapper>
      <SearchInputWrapper
        className={styles.promoCode}
        label={t("promoCode")}
        icon={
          <svg viewBox="0 0 24 24">
            <path d="M15 0.75H6.75L3.75 12H9.75L8.25 21L20.25 7.5H13.5L15 0.75Z" />
          </svg>
        }
        dropdown={brand.id === "libere" && <SearchInputPromoCodeDropdown />}
      >
        <input
          {...register("voucher")}
          placeholder={t("promoCodePlaceholder")}
        />
      </SearchInputWrapper>
      <Button
        className={styles.formSubmit}
        icon={
          <svg viewBox="0 0 24 24">
            <path d="m22.5 22.5-6.375-6.375" />
            <path d="M9.75 18.75a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
          </svg>
        }
      >
        <span className={styles.formSubmitText}>{t("findAccomodation")}</span>
      </Button>
    </form>
  );
};

const SearchInputWrapper: React.FC<{
  label: string;
  icon: ReactNode;
  error?: string;
  dropdown?: ReactNode;
  className?: string;
}> = ({ label, icon, error, children, dropdown, className }) => (
  <label className={classNames(styles.formField, className)}>
    <span className={styles.formFieldLabel}>
      {label}{" "}
      {error && (
        <>
          -<span className={styles.formFieldError}> {error}</span>
        </>
      )}
    </span>
    <div className={styles.formFieldInputWrap}>
      <div className={styles.formFieldIcon}>{icon}</div>
      {children}
    </div>
    {dropdown && <div className={styles.formFieldDropdown}>{dropdown}</div>}
  </label>
);

const SearchInputDatePicker: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  numberOfMonths: number;
}> = ({ control, numberOfMonths }) => {
  const { t } = useTranslation("commonSearchBar");
  const lang = useLanguage();
  const {
    field: { onChange: onFromChange, value: from },
  } = useController({
    name: "from",
    control,
    rules: { required: true },
    defaultValue: null,
  });
  const {
    field: { onChange: onToChange, value: to },
  } = useController({
    name: "to",
    control,
    rules: { required: true },
    defaultValue: null,
  });
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(datePickerRef, () => {
    setDatePickerVisible(false);
  });
  const crossOutPricesExperimentEnabled = useFeatureIsOn(
    "show-direct-channel-discount",
  );
  const isFeaturesReady = useFeaturesReady();
  return (
    <div className={styles.datePickerWrap}>
      <button
        className={styles.datePickerButton}
        type="button"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setDatePickerVisible(true);
        }}
      >
        <span
          className={classNames(styles.datePickerInput, {
            [styles.datePickerInputEmpty]: !from,
          })}
        >
          {(from && !isNaN(from.getTime()) && fromDate(from, lang as Locale)) ||
            t("dateFrom")}
        </span>
        <svg viewBox="0 0 24 24">
          <path d="M4.1 12h16.8" />
          <path d="m15.5 6.6 5.4 5.4-5.4 5.4" />
        </svg>
        <span
          className={classNames(styles.datePickerInput, {
            [styles.datePickerInputEmpty]: !to,
          })}
        >
          {(to && !isNaN(to.getTime()) && fromDate(to, lang as Locale)) ||
            t("dateTo")}
        </span>
      </button>
      {isDatePickerVisible && (
        <div className={styles.datepicker} ref={datePickerRef}>
          <LayoutSearchBarDatePicker
            from={from}
            to={to}
            onFromChange={onFromChange}
            onToChange={onToChange}
            locale={lang as unknown as Locale}
            onSelectionCompleted={() => setDatePickerVisible(false)}
            numberOfMonths={numberOfMonths}
          />
          <div className={styles.specialRates}>
            <div>
              <p className={styles.specialRatesTitle}>
                {t("stayLonger.title")}
              </p>
            </div>
            <div className={styles.specialRatesDiscounts}>
              <span>
                {t("stayLonger.plusNights", { nights: 7 })}:{" "}
                <strong>-15%</strong>
              </span>
              <span>
                {t("stayLonger.plusNights", { nights: 14 })}:{" "}
                <strong>-20%</strong>
              </span>
              <span>
                {t("stayLonger.plusNights", { nights: 21 })}:{" "}
                <strong>-25%</strong>
              </span>
              <span>
                {t("stayLonger.plusNights", { nights: 28 })}:{" "}
                <strong>
                  {t("stayLonger.percentageOrMore", { percentage: 40 })}
                </strong>
              </span>
            </div>
            <div className={styles.specialRatesDisclaimer}>
              *{" "}
              {t(
                `stayLonger.disclaimer${crossOutPricesExperimentEnabled && isFeaturesReady ? "_crossOutPricesExperiment" : ""}`,
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const SearchInputPromoCodeDropdown: React.FC = () => {
  const { t } = useTranslation("commonSearchBar");
  const lang = useLanguage();

  return (
    <div className={styles.specialRates}>
      <div>
        <p className={styles.specialRatesTitle}>
          {t("promoCodeSpecialRates.title")}
        </p>
      </div>
      <div className={styles.specialRatesDiscountsInline}>
        <NavLink
          href={`/${lang}/${t("common:pages.slug.groupTravel")}`}
          onClick={() => {
            notifyClick("groups");
          }}
        >
          <Link>{t("common:segments.groups")}</Link>
        </NavLink>
        <NavLink
          href={`/${lang}/${t("common:pages.slug.businessTravel")}`}
          onClick={() => {
            notifyClick("business");
          }}
        >
          <Link>{t("common:segments.business")}</Link>
        </NavLink>
        <NavLink
          href={`/${lang}/${t("common:pages.slug.travelAgencies")}`}
          onClick={() => {
            notifyClick("travel_agencies");
          }}
        >
          <Link>{t("common:segments.agencies")}</Link>
        </NavLink>
        <NavLink
          href={`/${lang}/${t("common:pages.slug.midStay")}`}
          onClick={() => {
            notifyClick("mid_stay");
          }}
        >
          <Link>{t("common:segments.midStay")}</Link>
        </NavLink>
      </div>
    </div>
  );
};

const Promotion: React.FC<{
  brand: Brand;
  promotion: PromotionType | null;
}> = ({ brand, promotion }) => {
  const { t } = useTranslation("commonSearchBar");
  const session = useSession();

  const isLoadingSession = !session.session;
  const isLeisure =
    !session.session?.accountId || session.session.accountType === "INHABITANT";

  const crossOutPricesExperimentEnabled = useFeatureIsOn(
    "show-direct-channel-discount",
  );
  const isFeaturesReady = useFeaturesReady();

  return (
    <div className={styles.promotion}>
      {isLoadingSession && (
        <p>
          <Skeleton width={300} />
        </p>
      )}
      {!isLoadingSession && !isLeisure && (
        <p>{t("commonSearchBar:promotion.notLeisure")}</p>
      )}
      {!isLoadingSession && isLeisure && promotion && (
        <>
          <p>
            <Trans
              i18nKey={
                promotion.translateKey
                  ? `commonSearchBar:promotion.${promotion.translateKey}`
                  : `commonSearchBar:promotion.campaign.discount_${promotion.discount.type}${crossOutPricesExperimentEnabled && isFeaturesReady ? "_crossOutPricesExperiment" : ""}`
              }
              components={{ strong: <strong /> }}
              values={{ discount: promotion.discount.amount }}
            />{" "}
            <Trans
              i18nKey={"commonSearchBar:promotion.campaign.promoCode"}
              components={{ strong: <strong /> }}
              values={{ promoCode: promotion.promoCode }}
            />{" "}
            {promotion.loyaltyApplies && (
              <>
                {" "}
                <Trans
                  i18nKey="commonSearchBar:promotion.campaign.loyaltyApplies"
                  components={{ linkCommunity: <CommunityLink /> }}
                  values={{ brand: brand.name }}
                />
              </>
            )}
          </p>
          <p className={styles.promotionTerms}>
            *
            {t(`commonSearchBar:promotion.campaign.terms`, {
              maxNights: promotion.maxNights,
              startDate: promotion.startDate,
              endDate: promotion.endDate,
            })}
          </p>
        </>
      )}
      {!isLoadingSession && isLeisure && !promotion && (
        <Trans
          i18nKey={`commonSearchBar:promotion.default.${brand.id}${crossOutPricesExperimentEnabled && isFeaturesReady ? "_crossOutPricesExperiment" : ""}`}
          components={{
            strong: <strong />,
            communityLink: <CommunityLink />,
          }}
          defaults={t("commonSearchBar:promotion.default.other")}
        />
      )}
    </div>
  );
};

const useOnClickOutside = (
  ref: React.RefObject<HTMLDivElement>,
  onClickOutside: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.target instanceof Node &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const CommunityLink: React.FC = ({ children }) => (
  <NavLink
    href="/community"
    onClick={() => {
      notifyClick("community");
    }}
  >
    <Link>{children}</Link>
  </NavLink>
);

const notifyClick = (label: string) => {
  window.dataLayer.push({
    event: "click",
    event_category: "search_bar",
    event_label: label,
  });
};

type LayoutSearchBarProps = {
  isScrolled?: boolean;
  className?: string;
  options: Array<{ title: string; uri: string }>;
  optionsDefault?: string;
  type: "asset" | "destination";
  brand: Brand;
};

type SearchFormFields = {
  assetUri: string;
  from: Date;
  to: Date;
  voucher: string;
};

export default LayoutSearchBar;
