import { isAfter, isBefore } from "date-fns";

import { Brand } from "types";

const promotionStartDate = new Date("2025-03-03T11:00:00+01:00");
const promotionEndDate = new Date("2025-04-28T00:00:00+01:00");

export const isAssetPromotionEnabled = (
  assetId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  brand: Brand,
): boolean => {
  const isAfterPromotionStart = isAfter(new Date(), promotionStartDate);
  const isBeforePromotionEnd = isBefore(new Date(), promotionEndDate);

  return (
    isAfterPromotionStart &&
    isBeforePromotionEnd &&
    [
      "1de5a6ea-1d96-46db-ab77-d031880eeba2", // LBSA
      "167f2b95-95de-4b29-bbab-5a2ab5a6db39", // LBLD
      "2b2c6df4-819d-4ca0-a331-52fe59050a43", // LCSM
      "39fcabed-ebca-4c4b-a052-f919c2ec34e3", // LMCH
      "8bb634de-47a5-4ace-95b4-f22eca721bff", // LMTR
      "42490f16-14ee-43f3-9b11-90f1c2f3b2f4", // LPYM
      "c1e4c1d6-14f5-402b-bb0c-4f0aa5b821d5", // LSAN
      "a29d75a0-9c88-41da-8ece-62d53362e77b", // LVAB
      "41753207-ae70-459b-8ffc-c7130e04d25d", // LVJB
      "d1e4c1d6-14f5-402b-bb0c-4f0aa5b821d6", // LVGA
      "47c83009-ebd0-4cef-9ebb-0ecced24fda5", // LLPR
    ].includes(assetId)
  );
};

export const promotionForAsset = (
  assetId: string,
  brand: Brand,
): Promotion | null =>
  isAssetPromotionEnabled(assetId, brand) ? promotionForBrand(brand) : null;

export const promotionForBrand = (brand: Brand): Promotion | null => {
  const isAfterPromotionStart = isAfter(new Date(), promotionStartDate);
  const isBeforePromotionEnd = isBefore(new Date(), promotionEndDate);
  if (
    ["libere", "koisi"].includes(brand.id) &&
    isAfterPromotionStart &&
    isBeforePromotionEnd
  ) {
    return {
      discount:
        brand.id === "libere"
          ? { type: "upTo", amount: 15 }
          : { type: "fixed", amount: 10 },
      promoCode: "SEMANASANTA",
      startDate: "11/04/2025",
      endDate: "27/04/2025",
      maxNights: 3,
      loyaltyApplies: brand.id === "libere",
    };
  }
  return null;
};

export type Promotion = {
  discount: { type: "fixed" | "upTo"; amount: number };
  promoCode: string;
  startDate: string;
  endDate: string;
  maxNights: number;
  translateKey?: string;
  loyaltyApplies?: boolean;
};
